import { getUrlParameter } from '/helpers/url';
import { invalidateSafariBFCache } from '/helpers/cache';

LemonFrog.Conversations = (function () {
  const moduleName = 'Conversations',
    navMessagesSel = '.js-nav-messages',
    unreadMessagesCounterSel = '.js-unread-counter',
    burgerMenuMessageIconSel = '.js-burger-messages-icon',
    indicatorSel = '.js-notifications-indicator',
    mobileDashboardMessagesSel = '.js-mobile-dashboard-messages',
    rejectModalSel = '.js-conversations-reject-candidate-modal',
    blockModalSel = '.js-conversations-block-confirmation-modal',
    reportModalSel = '.js-conversations-report-modal',
    conversationsInterfaceContainerSel = '.conversations-interface__container',
    tabs = ['inbox', 'sent', 'unanswered', 'archived']
  ;
  function init () {
    invalidateSafariBFCache();
    LemonFrog.TypingIndicator.init();
    LemonFrog.ConversationInterfaceFilters.init();
    LemonFrog.ConversationInterfaceUsersList.init();
    LemonFrog.ConversationInterfaceDialogBox.init();
    document.onvisibilitychange = function() {
      if (document.hidden) return;

      const hasNewMessages = $(`.js-message-snippet[data-conversation-id="${currentConversationId()}"]`)
        .find('.conversations-interface-users-list__user-last-messages-counter').length;
      if (!hasNewMessages) return;

      LemonFrog.UnreadMessages.clear(currentConversationId());
    }
  }

  function updateMessageCounters (navMessagesPartial, unreadMessagesCounterPartial, burgerMenuMessageIcon, indicatorPartial, dashboardMobilePartial) {
    $(navMessagesSel).html(navMessagesPartial);
    $(unreadMessagesCounterSel).html(unreadMessagesCounterPartial);
    $(burgerMenuMessageIconSel).replaceWith(burgerMenuMessageIcon);
    $(indicatorSel).html(indicatorPartial);
    $(mobileDashboardMessagesSel).html(dashboardMobilePartial);
  }

  function updateRejectCandidateModal (rejectCandidateModalPartial) {
    $(rejectModalSel).html(rejectCandidateModalPartial);
  }

  function updateBlockConfirmationModal (blockConfirmationPartial) {
    $(blockModalSel).html(blockConfirmationPartial);
  }

  function updateReportModal (reportModalPartial) {
    $(reportModalSel).html(reportModalPartial);
  }

  function updateConversationsInterface (conversationsInterfacePartial) {
    $(conversationsInterfaceContainerSel).html(conversationsInterfacePartial);
    sizeParentHeightEls();
    LemonFrog.ConversationInterfaceFilters.init();
  }

  function currentConversationId() {
    return $('.conversations-interface__conversation-inner').data('conversationId');
  }

  function currentTab() {
    const tabParam = getUrlParameter('tab');

    return tabs.includes(tabParam) ? tabParam : 'inbox';
  }

  return {
    moduleName: moduleName,
    init: init,
    currentConversationId: currentConversationId,
    currentTab: currentTab,
    updateMessageCounters: updateMessageCounters,
    updateRejectCandidateModal: updateRejectCandidateModal,
    updateBlockConfirmationModal: updateBlockConfirmationModal,
    updateReportModal: updateReportModal,
    updateConversationsInterface: updateConversationsInterface
  };
}());

LemonFrog.initModule(LemonFrog.Conversations);
